import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/index.scss'
import axios from 'axios';
import i18n from '@/plugins/i18n'
import VueTheMask from 'vue-the-mask'

axios.defaults.baseURL = process.env.APP_URL || 'https://qr.kusvkus.kz/api/';

createApp(App)
.use(store)
.use(router)
.use(i18n)
.use(VueTheMask)
.mount('#app')
