<template>
  <div class="home">
    <HeaderVue />
    <div class="container mx-auto">
      <div class="home__tab">
        <button @click="selected = item, v$.qrForm.$reset()" :class="selected?.value === item.value && 'active'"
          v-for="(item, idx) in tabList" :key="'tab' + idx">{{
            item.label }}</button>
      </div>
      <form onsubmit="return false" @submit="submit" class="home__form">
        <div class="hidden">
          <input id="categoryField" v-model="selected.label" />
          <input id="intInventNumberField" v-model="inventoryId" />

        </div>
        <div class="basicInput">
          <label>Описание<strong>*</strong></label>
          <textarea id="symptomsField" :class="{
            invalid:
              v$.qrForm.description.$dirty &&
              v$.qrForm.description.$invalid,
          }" v-model="qrForm.description" class="width90" placeholder="Введите описание..." />
          <p class="error" v-for="error of v$.qrForm.description.$errors" :key="error.$uid">
            {{ error.$message }}
          </p>
        </div>

        <div class="home__form-uploadImg">
          <div class="basicInput width90">
            <label>Прикрепить фото<strong v-if="selected.value == 'claim'">*</strong></label>
            <input id="fileUpload" hidden multiple type="file" @change="onFileChange($event)" />
            <button :class="{
              invalid:
                v$.qrForm.image.$dirty &&
                v$.qrForm.image.$invalid,
            }" class="home__form-uploadImg-btn" @click.prevent="chooseFiles()">
              Выбрать файл...
            </button>
            <p class="error" v-for="error of v$.qrForm.image.$errors" :key="error.$uid">
              {{ error.$message }}
            </p>
            <div v-if="images">
              <div class="home__form-uploadImg-img">
                <div class="home__form-uploadImg-img-list" v-for="(image, index) in images" :key="index">
                  <img :src="image" />
                  <button @click="removeImage(index)">Удалить фото</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="basicInput">
          <label>Вам нужна обратная связь?<strong v-if="selected.value == 'claim'">*</strong></label>
          <input id="nameField" :class="{
            invalid:
              v$.qrForm.name.$dirty &&
              v$.qrForm.name.$invalid,
          }" v-model="qrForm.name" class="width500" placeholder="ФИО" />
          <p class="error" v-for="error of v$.qrForm.name.$errors" :key="error.$uid">
            {{ error.$message }}
          </p>
          <input id="phoneField" :class="{
            invalid:
              v$.qrForm.phone.$dirty &&
              v$.qrForm.phone.$invalid,
          }" v-model="qrForm.phone" class="width500" placeholder="Номер телефона" v-mask="'+7 (###) ###-##-##'"
            type="tel" />
          <p class="error" v-for="error of v$.qrForm.phone.$errors" :key="error.$uid">
            {{ error.$message }}
          </p>
          <input id="emailField" :class="{
            invalid:
              v$.qrForm.email.$dirty &&
              v$.qrForm.email.$invalid,
          }" v-model="qrForm.email" class="width500" placeholder="E-mail" />
          <p class="error" v-for="error of v$.qrForm.email.$errors" :key="error.$uid">
            {{ error.$message }}
          </p>
        </div>
        <div class="home__form-checkbox">
          <div class="home__form-checkbox-list">
            <span><input :class="{
              invalid:
                v$.qrForm.checkMyData.$dirty &&
                v$.qrForm.checkMyData.$invalid,
            }" type="checkbox" id="checkbox" v-model="qrForm.checkMyData" />
            </span>
            <p class="f-12">
              Я, как пользователь Сервиса, даю <a @click="openModal('user')">согласие на сбор и обработку моих
                персональных данных</a>
            </p>
          </div>
          <div class="home__form-checkbox-list">
            <span><input :class="{
              invalid:
                v$.qrForm.checkPoliticConf.$dirty &&
                v$.qrForm.checkPoliticConf.$invalid,
            }" type="checkbox" id="checkbox" v-model="qrForm.checkPoliticConf" />
            </span>
            <p class="f-12">
              Я, согласен(на) с <a @click="openModal('politic')">политикой конфиденциальности</a>
            </p>
          </div>
        </div>
        <div class="home__form-submit grid gap-24 text-lg">
          <button :disabled="disableButton" class="width500" type="submit">Отправить</button>
          <a href="https://kusvkus.kz/" style="color: #07732C;
    text-decoration: underline;">Перейти на сайт kusvkus.kz</a>
        </div>
      </form>
    </div>
    <Modal :close-show="true" :open="openedModal" @close="openedModal = false">
      <div>
        <div class="publicText mt-12" v-html="modalHtml"></div>
      </div>
    </Modal>
  </div>
</template>

<script>
import HeaderVue from '@/components/Header.vue'
import { useVuelidate } from "@vuelidate/core";
import { sameAs } from "@vuelidate/validators";
import { required, minLength, email } from "@/plugins/i18n-validators";
import axios from "axios";
import Swal from 'sweetalert2'
import Modal from '@/components/modal.vue'

export default {
  name: 'HomeView',
  components: {
    HeaderVue,
    Modal
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  data() {
    return {
      tabList: [
        {
          label: 'Отзыв',
          value: 'review'
        },
        {
          label: 'Нужна помощь',
          value: 'support'
        }, {
          label: 'Претензия',
          value: 'claim'
        },
      ],
      selected: null,
      inventoryId: "",
      qrForm: {
        image: [],
        description: null,
        name: '',
        phone: '',
        email: '',
        checkPoliticConf: false,
        checkMyData: false,


      },
      images: [],
      openedModal: false,
      modalHtml: '',
      disableButton: false,
    }
  },
  validations() {
    return {
      qrForm: {
        description: {
          required,
          minLength: minLength(10),
        },
        name: {
          required,
          minLength: minLength(4),
        },
        phone: {
          required,
          minLength: minLength(18),
        },
        email: {
          required,
          email
        },
        checkPoliticConf: {
          required,
          sameAs: sameAs(true),
        },
        checkMyData: {
          required,
          sameAs: sameAs(true),
        },
        image: {
          required
        }
      },
    };
  },
  created() {
    if (this.selected === null) {
      this.selected = this.tabList[0]
    }
  },
  mounted() {
    if (this.$route?.query?.inventory) {
      this.inventoryId = this.$route?.query?.inventory
    }
  },
  methods: {
    onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      this.qrForm.image.push(event.target.files);
      console.log('qrForm.image', this.qrForm.image);
      this.createImage(files);
    },
    createImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {

          const imageUrl = event.target.result;

          vm.images.push(imageUrl);
        };
        reader.readAsDataURL(files[index]);
      }
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },

    chooseFiles() {
      document.getElementById("fileUpload").click();
    },
    submit(event) {
      if (this.selected.value === 'claim') {
        this.v$.qrForm.$touch()
      } else {
        this.v$.qrForm.description.$touch()
        this.v$.qrForm.checkMyData.$touch()
        this.v$.qrForm.checkPoliticConf.$touch()
      }
      if (this.selected.value === 'claim' ? !this.v$.qrForm.$invalid : (!this.v$.qrForm.description.$invalid && !this.v$.qrForm.checkPoliticConf.$invalid && !this.v$.qrForm.checkMyData.$invalid)) {
        let data = new FormData();
        data.append("description", this.qrForm.description);
        data.append("name", this.qrForm.name);
        data.append("phone", this.qrForm.phone);
        data.append("email", this.qrForm.email);
        data.append("inventory_id", this.inventoryId);
        if (this.qrForm.image && this.qrForm.image.length) {
          let images = this.qrForm.image[0]
          for (let i = 0; i < images.length; i++) {
            let file = images[i];
            data.append(`image[]`, file);
          }
        }
        data.append("agreeData", this.qrForm.checkMyData);
        data.append("agreeConfidential", this.qrForm.checkPoliticConf);
        this.disableButton = true;
        axios
          .post(this.selected.value, data, {})
          .then((response) => {
            if (response.status === 200) {
              if (response.data.image) {
                const baseUrl = "https://qr.kusvkus.kz";
                const imageUrls = response.data.image
                if (imageUrls && imageUrls.length) {
                  const images = imageUrls.map(image => `${baseUrl}${image.editedPath}`)
                    .join('; ');
                  let inputElement = document.createElement("input");
                  inputElement.setAttribute("type", "hidden");
                  inputElement.setAttribute("id", "intPhoto");
                  inputElement.setAttribute("value", images);
                  event.target.appendChild(inputElement);
                }
              }
              createObject(event)
              this.qrForm.image = [],
                this.qrForm.description = null,
                this.qrForm.name = '',
                this.qrForm.phone = '',
                this.qrForm.email = '',
                this.qrForm.checkPoliticConf = false,
                this.qrForm.checkMyData = false
              this.images = []
              this.v$.$reset()
              Swal.fire({
                title: 'Спасибо за обращение!',
                icon: "success",
                showConfirmButton: false,
              })

            } else {
              console.log(response);
            }
          })
          .finally(() => {
            this.disableButton = false
          })
          .catch((e) => {
          });
      } else {
        console.log('alert', this.v$.qrForm.description.$invalid);
      }
    },
    openModal(value) {
      console.log('value', value);
      const text1 = `<p>
    <h1>Пользовательское соглашение</h1>
</p>
<p>
    <strong><span>1. Общие условия использования</span></strong><br /><span>1.1. Настоящее Пользовательское соглашение</span><span> </span><em><span>(далее — «Соглашение»)</span></em><span> устанавливает правила использования Сервиса обращений «kusvkus.kz»</span><span> </span><em><span>(далее – Сайт)</span></em><span> и представляет собой Соглашение между ТОО «Aitas meаt distribution», БИН 150340018687, юридический адрес: 070000, РК, Восточно-Казахстанская область, г. Усть-Каменогорск, Самарское шоссе,2 (далее</span><em><span>-Владелец)</span></em><span> и лицом (юридическое, физическое), являющимся пользователем Сайта</span><span> </span><em><span>(далее — «Пользователь»)</span></em><span>.</span>
</p>
<p>
    <strong><span>2. Предмет соглашения</span></strong><br /><span>2.1. Предметом настоящего Соглашения является оказание Владельцем услуг по предоставлению возможности Пользователю направлять запросы с Сайта и получать ответы на направленные обращения.</span>
</p>
<p>
    <strong><span>3. Правила регистрации</span></strong><br /><span>3.1.</span><span> Настоящее соглашение является публичной офертой.</span><br /><span>3.2. Для того чтобы получить ответ на свое обращение / претензию на сайте, Пользователь должен оставить свои контактные данные.</span><br /><span>3.3. Пользователь соглашается предоставлять правдивую, точную и полную Информацию о себе.</span><br /><span>3.4. Если лицо предоставляет неверную регистрационную информацию или имеются основания думать, что предоставленная лицом регистрационная информация недостоверна, неполна, неточна, нарушает условия настоящего Соглашения или, что лицо использует чужие данные, то Владелец Сайта имеет право оставить</span><span> приостановить либо отменить авторизацию.</span>
</p>
<p>
    <strong><span>4. Персональные данные Пользователя</span></strong><br /><span>4.1. Заключая настоящее соглашение и предоставляя персональные данные, согласно приложению к настоящему соглашению, Пользователь выражает согласие на сбор и обработку персональных данных владельцу Сайта (включая систематизацию, накопление, хранение (в том числе на случай предъявления претензий), уточнение (обновление, изменение), использование (в том числе для целей индивидуального общения с Участниками) обезличивание, блокирование и уничтожение, размещение/указание предоставленных персональных данных.</span>
</p>
<p>
    <strong><span>5. Права и обязанности Пользователя</span></strong><br /><span>5.1.</span><span> Пользователь обязан предоставить Владельцу точную и полную информацию при регистрации.</span><br /><span>5.2. Пользователь обязан не нарушать умышленно или случайно законодательные акты Республики Казахстан.</span><br /><span>5.3. Пользователь обязан не выдавать себя за другое лицо, не использовать материалы, содержащие вирусы или другое вредоносное программное обеспечение.</span>
</p>
<p>
    <strong><span>6. Права и обязанности Владельца</span></strong><br /><span>6.1. Владелец вправе в случае необходимости проверять достоверность введенных данных Пользователя, в том числе путем обращения в государственные органы и организации.</span>
</p>
<p>
    <strong><span>7. Вступление соглашения в силу</span></strong><br /><span>7.1.</span><span> Соглашение вступает в силу с момента подачи обращения / претензии на Сайте.</span><br /><span>7.2. Настоящее Соглашение полностью или частично может быть изменено владельцем в любое время, без какого-либо специального уведомления. Новая редакция Соглашения вступает в силу с момента ее опубликования на Сайте.</span>
</p>
<p>
    <strong><span>8. Ответственность сторон</span></strong><br /><span>8.1. Пользователь несет полную ответственность за достоверность предоставляемых данных при подаче обращения / претензии на Сайте.</span>
</p>
<p>
    <strong><span>Согласие на сбор и обработку персональных данных</span></strong>
</p>
<p>
    <span>Настоящим я, как пользователь Сервиса, даю согласие на сбор и обработку моих персональных данных между ТОО «Aitas meаt distribution», БИН 150340018687.</span><br /><span>Настоящее согласие действует в течение срока, необходимого для достижения цели сбора и обработки персональных данных, а также в течение срока, установленного применимым законодательством.</span><br /><span>Цели сбора и обработки персональных данных; перечень собираемых данных;</span><span> сведения о наличии трансграничной передачи персональных данных в процессе их обработки и иные сведения, определяемые Компанией в связи со сбором и обработкой персональных данных, регламентируются в соответствии с Политикой конфиденциальности Компании и Пользовательским соглашением.</span><br /><span>Распространение персональных данных в общедоступных источниках информации не предполагается.</span><br /><span>Я прочитал/а условия и согласен на сбор и обработку персональных данных, а также на осуществление иных действий, указанных в Политике конфиденциальности и Пользовательском соглашении.</span><br /><br /><span>Редакция действует с 01 июля 2024 года.</span>
</p>
`
      const text2 = `<p>
    <strong><h1>Политика конфиденциальности</h1></strong>
</p>
<p>
    <span>Политика конфиденциальности и условия, описанные ниже, являются частью условий использования сайте kusvkus.kz (далее - Сайт).</span>
</p>
<p>
    <span>Личная информация о Вас может включать: Ваше ФИО, ИИН, адрес электронной почты, номер телефона или другую информацию, которая идентифицирует Вас лично. Посещение Сайта не требует от Вас регистрации и предоставления личной информации.</span>
</p>
<p>
    <span>Вся личная информация о Вас используется только по своему прямому назначению. Используя Сайт, Вы соглашаетесь с условиями настоящей Политики конфиденциальности.</span>
</p>
<p>
    <span>Предоставление личных данных на нашем Сайте является добровольным. Передавая их, Вы разрешаете Сайту использовать информацию для заявленной цели. Однако не предоставление определенной информации может создать препятствия для оказания услуги, которую Вы хотели бы получить.</span>
</p>
<p>
    <span>Сайт позволяется публиковать Ваши комментарии/обращения/претензии. Ваши сообщения могут быть общедоступными.</span>
</p>
<p>
    <span>Обращаясь к Сайтам с Вашими вопросами и комментариями, Вы НЕ должны включать дополнительную личную информацию.</span>
</p>
<p>
    <span>Если Вы решите предоставить личные данные на Сайте, используя такие методы, как заполнение веб-формы или отправка электронного письма, информация будет использована, чтобы помочь предоставить Вам информацию или услугу, которую Вы запросили, или ответить на Ваше сообщение.&nbsp;</span>
</p>
`
      if (value == 'user') {

        this.modalHtml = text1
      } else {
        this.modalHtml = text2

      }
      this.openedModal = !this.openedModal
    }
  }
}
</script>

<style scoped lang="scss">
:root {
  --toggle-bg-off: #2ea147;
  --toggle-bg-on: #ff0000;
  --toggle-border-on: #ff0000;
  --toggle-border-off: #2ea147;
}

.home {
  margin-bottom: 100px;

  &__tab {
    display: flex;
    gap: 10%;
    margin: 100px 0;

    @media (max-width: 992px) {
      gap: 5%;
    }

    button {
      padding: 25px 100px;
      border: 1px solid #07732C80;
      color: #07732C80;
      font-size: 23px;
      border-radius: 10px;
      transition: all ease .5s;

      @media (max-width: 1280px) {
        padding: 20px 40px;
      }

      @media (max-width: 768px) {
        padding: 15px 25px;
        font-size: 18px;
        border-radius: 7px;
      }

      @media (max-width: 640px) {
        font-size: 11px;
        padding: 10px 20px;
        border-radius: 5px;

      }
    }

    .active {
      background-color: #07732C;
      color: #FFF;
      transition: all ease .5s;
      cursor: default;
    }
  }

  &__form {
    &-uploadImg {
      margin-bottom: 50px;

      &-btn {
        display: flex;
        flex-direction: row;
        padding: 25px 30px;
        gap: 10px;
        border: 1px solid #0000004D;
        width: 100%;
        border-radius: 10px;
        color: #0069CA;
        font-size: 23px;

        @media (max-width: 768px) {
          font-size: 17px;
        }
      }

      &-img {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &-list {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          align-content: flex-start;
          margin-top: 30px;

          button {
            color: #FF0000;
            font-size: 23px;

            @media (max-width: 768px) {
              font-size: 17px;
            }
          }
        }

        img {
          max-width: 150px;
          width: 100%;
          height: 100%;
          max-height: 100px;
          object-fit: cover;

          @media (max-width: 768px) {
            max-width: 60px;
          }
        }
      }
    }

    &-checkbox {
      &-list {
        display: flex;
        flex-direction: row;
        gap: 15px;
        font-size: 23px;
        color: #000000;

        @media (max-width: 768px) {
          font-size: 14px;
        }

        input {
          height: 20px;
          width: 20px;
          border: 2px solid #555555;
        }

        span {
          width: 20px;
          height: 20px;
        }

        input[type=checkbox] {
          position: relative;
          cursor: pointer;
        }

        input[type=checkbox]:before {
          content: "";
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          top: 0;
          left: 0;
          background-color: #e9e9e9;

        }

        input[type=checkbox]:checked:before {
          content: "";
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          top: 0;
          left: 0;
          background-color: #07732C;

        }

        input[class=invalid]:before {
          border: 1px solid #FF0000;
        }

        input[type=checkbox]:checked:after {
          content: "";
          display: block;
          width: 9px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;

          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          position: absolute;
          top: 2px;
          left: 6px;
        }

        a {
          color: #07732C;
          text-decoration: underline;
        }
      }
    }

    &-submit {
      margin-top: 50px;

      button {
        border-radius: 10px;
        border: 1px solid #07732C;
        background-color: #07732C;
        transition: all ease .5s;
        padding: 25px;
        color: #FFF;
        font-size: 23px;

        @media (max-width: 768px) {
          font-size: 17px;
        }

        &:disabled {
          border: 1px solid gray;
          background-color: gray;
          transition: all ease .5s;
        }

        &:hover {
          background-color: #FFF;
          color: #07732C;
          transition: all ease .5s;
        }
      }
    }
  }
}

.width500 {
  width: 500px;
  max-width: 100%;

  @media (max-width: 1280px) {
    width: 30vh;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
}

.width90 {
  width: 1000px;
  max-width: 100%;

  @media (max-width: 1280px) {
    width: 55vh;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
}
</style>
