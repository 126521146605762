<template>
    <header class="header ">
        <div class="container mx-auto">
          
            <div class="header__text">
                <p>Сервис обращений</p>
            </div>
            <div class="header__logo">
                <img class="cursor-pointer" @click="toHome()" src="@/assets/mainLogo.svg" alt="@/assets/mainLogo.svg">
            </div>
        </div>
    </header>
</template>

<script>
export default{
    methods:{
        toHome(){
            window.location.href = 'https://kusvkus.kz/'
        }
    }
}
</script>

<style scoped lang="scss">
.header {
    background: #1F913B26;
    margin-top: 80px;
    min-height: 400px;

    @media (max-width: 640px) {
        min-height: 200px;
        border-radius: 10px;
        margin-left: 5%;
        margin-right: 5%;
    }

    .container {
        position: relative;
    }

    &__logo {
        position: absolute;
        left: 0;
        top: -200px;

        img {
            max-width: 350px;
            z-index: 10!important;
            @media (max-width: 640px) {
                max-width: 140px;
            }
        }

        @media (max-width: 640px) {
            top: -70px;
        }
    }

    &__text {
        p {
            color: #000000;
            font-size: 45px;
            font-weight: 500;
            padding-top: 200px;
            position: relative;
            @media (max-width: 640px) {
                padding-top: 100px;
            }
            @media (max-width: 640px) {
                font-size: 23px;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: -20px;
                width: 100px;
                height: 5px;
                background-color: #1A8E3B;
                left: 0;

                @media (max-width: 640px) {
                    width: 50px;
                }
            }
        }



    }
}
</style>